import { BrowserRouter, Route, Routes ,useLocation} from "react-router-dom";
import "./App.css";


import Header from "./components/Header";
import Footer from "./components/Footer";
import Whatsapp from "./components/whatsappConnect";

import Home from "./pages/home";
import Solutions from "./pages/solutions";
import AboutUs from "./pages/about";
import Portfolio from "./pages/portfolio";
import Carrers from "./pages/career";
import Description from "./pages/description";
import ContactUs from "./pages/contact";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlankPage from "./pages/BlankPage";
import Webdevelopment from "./pages/Webdevelopment";
import Mobdevelopment from "./pages/Mobdevelopment";
import Customsoftdevelopment from "./pages/Customsoftdevelopment";
import Blockchain from "./pages/Blockchain";
import IOT from "./pages/IOT";
import DigitalMarketing from "./pages/DigitalMarketing";
import CloudService from "./pages/CloudService";
import ItCounslant from "./pages/ItCounslant";
import Uiux from "./pages/Uiux";
import Thankyou from "./pages/Thankyou";



// export default function App() {
//   const location = useLocation();
//   return (
//     <BrowserRouter>
//       <div className="">
//         <Header />
//         <ToastContainer
//           position={"top-right"}
//           autoClose={2500}
//           hideProgressBar={true}
//           closeOnClick={true}
//           pauseOnHover={true}
//           draggable={true}
//           progress={undefined}
//         />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           {/* <Route path="/solutions" element={<Solutions />} /> */}
//           <Route path="/about-us" element={<AboutUs />} />
//           <Route path="/portfolio" element={<Portfolio />} />
//           <Route path="/careers" element={<Carrers />} />
//           <Route path="/careers/open-position" element={<Description />} />
//           <Route path="/contact-us" element={""} />
//           <Route path="/privacy-policy" element={<BlankPage />} />
//           <Route path="/thankyou" element={<Thankyou />} />
//           <Route path="/webdevelopment" element={<Webdevelopment />} />
//           <Route path="/mobiledevelopment" element={<Mobdevelopment />} />
//           <Route
//             path="/customdevelopment"
//             element={<Customsoftdevelopment />}
//           />
//           <Route path="/blockchain" element={<Blockchain />} />
//           <Route path="/iot" element={<IOT />} />
//           <Route path="/digitalmarketing" element={<DigitalMarketing />} />
//           <Route path="/cloudsecurity" element={<CloudService />} />
//           <Route path="/itconsultant" element={<ItCounslant />} />
//           <Route path="/uiux" element={<Uiux />} />
//         </Routes>
//         <Whatsapp />
//         {location.pathname !== "/thankyou" && <ContactUs />}
//         <Footer />
//       </div>
//     </BrowserRouter>
//   );
// }

function App() {
  return (
    <BrowserRouter>
      <MainLayout />
    </BrowserRouter>
  );
}

function MainLayout() {
  const location = useLocation(); // useLocation now inside BrowserRouter

  return (
    <div>
      <Header />
      <ToastContainer
        position={"top-right"}
        autoClose={2500}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/careers" element={<Carrers />} />
        <Route path="/careers/open-position" element={<Description />} />
        <Route path="/contact-us" element={""} />
        <Route path="/privacy-policy" element={<BlankPage />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/webdevelopment" element={<Webdevelopment />} />
        <Route path="/mobiledevelopment" element={<Mobdevelopment />} />
        <Route path="/customdevelopment" element={<Customsoftdevelopment />} />
        <Route path="/blockchain" element={<Blockchain />} />
        <Route path="/iot" element={<IOT />} />
        <Route path="/digitalmarketing" element={<DigitalMarketing />} />
        <Route path="/cloudsecurity" element={<CloudService />} />
        <Route path="/itconsultant" element={<ItCounslant />} />
        <Route path="/uiux" element={<Uiux />} />
      </Routes>
      <Whatsapp />
      {location.pathname !== "/thankyou" && <ContactUs />}
      <Footer />
    </div>
  );
}

export default App;
