export const ProjectItem = [
  {
    img: require("../../images/Sanjoli11.png"),
    heading: "Sanjoli ",
    subHeading: "Ecommerce",
    popImg: [
      {
        pop: require("../../images/Sanjoli11.png"),
      },
      {
        pop: require("../../images/snajolipopup2.png"),
      },
    ],
    para: [
      {
        para: "SanjoliSarees.com is an E-commerce platform providing customers with an interface to purchase desired sarees among the listed products by create an engaging and seamless user experience. Sanjoli Sarees is one of the leading saree brands in Gujarat with a sale of over 10 lacs sarees per year.",
      },
      {
        para: "It is one of the leading saree brands in Gujarat.",
      },
    ],
    link: "https://www.sanjolisarees.com/",
  },

  {
    img: require("../../images/Chavi1.png"),
    heading: "Chhavi ",
    subHeading: "Interior Design and Architecture",
    popImg: [
      {
        pop: require("../../images/Chavi1.png"),
      },
      {
        pop: require("../../images/chavipopup2.png"),
      },
    ],
    para: [
      {
        para: "Studio Chhavi sought to streamline their design processes, enhance client collaboration, and present their portfolio in an engaging manner through intuitive user interfaces. We developed a digital solution that aligns with their vision of elegance and innovation, providing a seamless experience for both clients and designers.By integrating advanced design tools and features, Chhavi Studio can now showcase their work effectively while fostering stronger relationships with clients.",
      },
      {
        para: "A digital solution that aligned with their vision of elegance and innovation.",
      },
    ],
    link: "https://studiochhavi.in/",
  },

  {
    img: require("../../images/Ap11.png"),
    heading: "AP Enterprises",
    subHeading: "Invoice Generation",
    popImg: [
      {
        pop: require("../../images/Ap11.png"),
      },
      {
        pop: require("../../images/Appopup2.png"),
      },
    ],
    para: [
      {
        para: "A P Enterprises is a client-specific, user-friendly web app designed for efficient invoice management. It enables the admin to generate, edit, and track invoices seamlessly while providing secure PDF generation and download functionalities.This solution streamlines the invoicing process, ensuring that businesses can manage their financial transactions with ease and accuracy. ",
      },
    ],
    link: "https://apbusiness.co.in/",
  },
  {
    img: require("../../images/ignis.png"),
    heading: "Ignis",
    subHeading: "Construction",
    popImg: [
      {
        pop: require("../../images/ignis.png"),
      },
      {
        pop: require("../../images/ignispopup2.png"),
      },
    ],
    para: [
      {
        para: "IGNIS redefines the real estate experience with a dynamic website designed to showcase buyers, sellers, and agents seamlessly. It offers intuitive navigation, comprehensive property listings, and interactive tools to streamline the home buying and selling process.",
      },
    ],
    link: "https://ignispropertyexpo.com/",
  },
  {
    img: require("../../images/finexca1.png"),
    heading: "Finexa Capital",
    subHeading: "Fintech ",
    popImg: [
      {
        pop: require("../../images/finexca1.png"),
      },
      {
        pop: require("../../images/finexapopup2.png"),
      },
    ],
    para: [
      {
        para: "Finexa Capital aimed to streamline financial services, foster closer client relationships, and showcase their comprehensive loan portfolio along with a wide range of SME services. The platform serves as a one-stop solution for diverse customer needs, highlighting Finexa's expertise in providing tailored financial solutions.By leveraging cutting-edge technology and a customer-centric approach, Finexa Capital empowers clients to navigate the complex financial landscape with confidence.",
      },
    ],
    link: "https://finexacapital.com/",
  },
  {
    img: require("../../images/kashimg1.png"),
    heading: "Kaash Studio",
    subHeading: "3D Printing and Designing ",
    popImg: [
      {
        pop: require("../../images/kashimg1.png"),
      },
      {
        pop: require("../../images/kashpopup2.png"),
      },
    ],
    para: [
      {
        para: "Kaash Studio is a 3D printing hub that offers bespoke solutions characterized by unparalleled quality and a strong commitment to two dynamic industries. By combining expertise, innovation, and state-of-the-art technology, they bring ideas to life in stunning detail, providing tailored services for both the interior and commercial sectors.With a focus on precision and creativity, Kaash Studio ensures that every project meets the highest standards, and their dedication to excellence positions them as a trusted partner in the 3D printing landscape.",
      },
    ],
    link: "https://kaashstudio.com/",
  },
  {
    img: require("../../images/amimg1.png"),
    heading: "The Additive Minds",
    subHeading: "Additive Manufacturing",
    popImg: [
      {
        pop: require("../../images/amimg1.png"),
      },
      {
        pop: require("../../images/Ampopup2.png"),
      },
    ],
    para: [
      {
        para: "Additive Minds is a 3D printing and manufacturing service provider specializing in precision and quality for the commercial sector. They integrate advanced technologies and innovative processes to deliver high-quality prototypes, products, and components with exceptional efficiency.By focusing on the latest 3D printing technologies, Additive Minds ensures tailored solutions that meet the specific needs of their clients.",
      },
    ],
  },
  {
    img: require("../../images/Path2study1.png"),
    heading: "Path2study",
    subHeading: "Edtech",
    popImg: [
      {
        pop: require("../../images/Path2study1.png"),
      },
      {
        pop: require("../../images/p2spopup2.png"),
      },
    ],
    para: [
      {
        para: "At Path2Study, we aim to provide scholarship and student loan information in an intuitive, user-friendly way that caters to students seeking financial assistance for their education. Our platform addresses the challenges students face when navigating scholarship and loan options, offering a comprehensive resource to help them make well-informed decisions about their financial future. With personalized tools and resources, Path2Study empowers students to explore various funding opportunities, ensuring they can pursue their educational goals with confidence.",
      },
    ],
    link: "https://path2study.com/",
  },
  {
    img: require("../../images/ize11.png"),
    heading: "IZE",
    subHeading: "Print on Demand ",
    popImg: [
      {
        pop: require("../../images/ize11.png"),
      },
      {
        pop: require("../../images/izepopup2.png"),
      },
    ],
    para: [
      {
        para: "At IZE, we developed a cutting-edge Print-on-Demand e-commerce platform that empowers artists and designers to showcase their creations in a lifelike 3D environment. The platform offers distinct features for both artists and customers, ensuring an efficient and engaging experience while navigating the site. With its intuitive interface and advanced visualization tools, IZÉ makes it easy for users to explore, customize, and purchase unique products directly from their favorite creators.",
      },
    ],
  },
  {
    img: require("../../images/SSimg1.png"),
    heading: "SS Service",
    subHeading: "Erosion Monitoring System",
    popImg: [
      {
        pop: require("../../images/SSimg1.png"),
      },
      {
        pop: require("../../images/sspopup2.png"),
      },
    ],
    para: [
      {
        para: "We have engineered a state-of-the-art IoT device and accompanying web application to revolutionize erosion monitoring. This cutting-edge solution is designed to provide real-time data and advanced analytics, ensuring precise monitoring and effective management of erosion in various environments.Our custom-built IoT device is equipped with high-precision sensors that continuously monitor and record critical data, including running hours and erosion percentage. The integrated web application serves as the central hub for data visualisation and analysis. It displays comprehensive metrics and analytics derived from the IoT device, offering users a detailed overview of erosion trends. The platform's intuitive interface enables users to access critical insights and calculations with ease, empowering them to make informed decisions to mitigate risks and extend the lifespan of their infrastructure.",
      },
    ],
    link: "https://www.epmssolutions.com",
  },

  {
    img: require("../../images/svayou.png"),
    heading: "Svayou",
    subHeading: "Healthcare",
    popImg: [
      {
        pop: require("../../images/svayou.png"),
      },
      {
        pop: require("../../images/svayoupopup2.png"),
      },
    ],
    para: [
      {
        para: "A comprehensive website was designed and developed for Svayou Healthcare, prioritizing user experience and data security. The intuitive interface allows for efficient patient management and healthcare delivery, ensuring accessible healthcare services while fostering a healthier future for all communities.",
      },
    ],
    // link: "https://svayou.com/",
  },

  {
    img: require("../../images/vedant.png"),
    heading: "Vedant Buiders",
    subHeading: "Construction",
    popImg: [
      {
        pop: require("../../images/vedant.png"),
      },
      {
        pop: require("../../images/vedantpopup2.png"),
      },
    ],
    para: [
      {
        para: "Vedant Builders is Pune’s trusted name in real estate, offering high-quality residential and commercial spaces. With a commitment to excellence, they cater to first-time homebuyers, seasoned investors, and businesses alike. Their tailored approach ensures every client’s vision is brought to life, making Vedant Builders a leader in customer-centric real estate solutions.",
      },
    ],
    link: "https://vedantbuilders.com/",
  },
  {
    img: require("../../images/rachana.png"),
    heading: "Rachna",
    subHeading: "Textile Manufacturing",
    popImg: [
      {
        pop: require("../../images/rachana.png"),
      },
      {
        pop: require("../../images/rachanapopup2.png"),
      },
    ],
    para: [
      {
        para: "For Rachna Group, one of the oldest textile manufacturers in Surat since 1978, a visually appealing website was created to highlight their extensive range of designer sarees, kurties, western wear, and fabrics. The WordPress-based platform emphasizes the brand's rich heritage and commitment to quality while providing an engaging shopping experience for customers. With features designed for easy browsing and product discovery, the website effectively connects Rachna Group with its audience, reinforcing its position as a leader in the textile industry.",
      },
    ],
    link: "https://www.rachnagroup.com/",
  },
  {
    img: require("../../images/eminent.png"),
    heading: "Eminent",
    subHeading: "Kraft Paper Manufacturing",
    popImg: [
      {
        pop: require("../../images/eminent.png"),
      },
      {
        pop: require("../../images/eminentpopup2.png"),
      },
    ],
    para: [
      {
        para: "A comprehensive website was developed for Eminent Paper Industries LLP, a Kraft paper manufacturing plant based in Kosamba, Surat. The site effectively showcases the company's offerings and capabilities, providing essential information about their manufacturing processes and product range. Utilizing WordPress, the platform features an intuitive layout that enhances user experience and facilitates easy navigation, ensuring potential clients can quickly access the information they need about this growing enterprise.",
      },
    ],
    link: "https://eminentpaper.com/",
  },
  {
    img: require("../../images/yande.png"),
    heading: "Yande Polymers",
    subHeading: "Polymer Manufacturing",
    popImg: [
      {
        pop: require("../../images/yande.png"),
      },
      {
        pop: require("../../images/yandepopup2.png"),
      },
    ],
    para: [
      {
        para: "A comprehensive website was developed for Eminent Paper Industries LLP, a Kraft paper manufacturing plant based in Kosamba, Surat. The site effectively showcases the company's offerings and capabilities, providing essential information about their manufacturing processes and product range. Utilizing WordPress, the platform features an intuitive layout that enhances user experience and facilitates easy navigation, ensuring potential clients can quickly access the information they need about this growing enterprise.",
      },
    ],
    link: "https://eminentpaper.com/",
  },

  {
    img: require("../../images/yaha.png"),
    heading: "Yaha Water",
    subHeading: "Water Filter Manufacturing",
    popImg: [
      {
        pop: require("../../images/yaha.png"),
      },
      {
        pop: require("../../images/yahapopup2.png"),
      },
    ],
    para: [
      {
        para: "A robust website was developed for Yaha Water Systems, enhancing their ability to deliver innovative water engineering solutions as they specialise in comprehensive water and wastewater treatment solutions, including sea/river water intake and distribution systems. The platform features an intuitive interface that showcases product categories like  Self cleaning filters and Active Media filters, supporting their mission to provide safe drinking water and efficient treatment processes across various sectors.",
      },
    ],
    link: "https://yahawater.in/",
  },
  {
    img: require("../../images/smv.png"),
    heading: "Start My Venture",
    subHeading: "Business Consultation",
    popImg: [
      {
        pop: require("../../images/smv.png"),
      },
      {
        pop: require("../../images/smvpopup2.png"),
      },
    ],
    para: [
      {
        para: " An innovative online platform was designed for Start My Venture, tailored specifically for aspiring entrepreneurs. The user-friendly interface and essential tools simplify the process of starting and managing businesses. This platform empowers users with valuable resources, enabling them to turn their entrepreneurial dreams into reality while saving time and money.",
      },
    ],
    link: "https://startmyventure.in/",
  },
  {
    img: require("../../images/aatman.png"),
    heading: "Aatman Psychology Studio",
    subHeading: "Healthcare",
    popImg: [
      {
        pop: require("../../images/aatman.png"),
      },
      {
        pop: require("../../images/aatmanpopup2.png"),
      },
    ],
    para: [
      {
        para: "A secure and accessible online platform was developed for Aatman Psychology Studio, focusing on mental health services. It features easy access to resources, professional support, and an appointment booking system with several set of plans, promoting mental well-being and helping to break the stigma surrounding mental health.",
      },
    ],
    link: "https://aatmanpsychologystudio.in/",
  },
];

