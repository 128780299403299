import React from 'react'
import './Solutiondrop.css'
import {NavLink, Link, useNavigate ,useLocation} from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";

function Solutiondrop() {
  const navigate=useNavigate();

  const handleTouch=()=>{
navigate("/contact-us");


  }

   const location = useLocation();
  return (
    <div>
      <div className="bgsolution w-full">
        <div className="d-flex p-3 ">
          <div className="col-md-5 p-md-3">
            <h4 className="">Services</h4>
            <p className="text-secondary mt-md-3">
              Datart Infotech crafts expert solutions to elevate your buisiness
              growth. Experience customized services for your success story.
            </p>
            <div className="d-flex">
              <div
                className="btn border col-5  mt-md-4 rounded-5 abobutton"
                onClick={handleTouch}
              >
                Get in touch
              </div>
              <div onClick={handleTouch}>
                <img
                  src={require("../../images/homeImages/aboutround.png")}
                  className="img-fluid  col-7 mt-md-4 "
                />
              </div>
            </div>
          </div>
          <div className="col-md-7 p-2">
            <h5>SERVICES</h5>
            <hr />
            <div className="d-flex">
              <div className="col-md-6">
                {/* <div className="d-flex align-items-center  gap-2 mt-md-3">
                  <FaArrowRightLong />
                  <Link
                    as={Link}
                    to="/webdevelopment"
                    className="text-decoration-none text-black "
                  >
                    Web Development
                  </Link>
                </div> */}
                <div className="d-flex align-items-center gap-2 mt-md-2  forhover-parent">
                  <FaArrowRightLong
                    className="text-decoration-none   arrow-icon"
                    style={{
                      color:
                        location.pathname === "/webdevelopment"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <NavLink
                    to="/webdevelopment"
                    className="text-decoration-none  nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#0FA4D2" : "black",
                    })}
                  >
                    Web Development
                  </NavLink>
                </div>
                <div className="d-flex align-items-center  gap-2 forhover-parent">
                  <FaArrowRightLong
                    className="text-decoration-none forhover arrow-icon "
                    style={{
                      color:
                        location.pathname === "/mobiledevelopment"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <NavLink
                    as={Link}
                    to="/mobiledevelopment"
                    className="text-decoration-none forhover nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#0FA4D2" : "black",
                    })}
                  >
                    Mobile Development
                  </NavLink>
                </div>
                <div className="d-flex align-items-center  gap-2  forhover-parent">
                  <FaArrowRightLong
                    className="text-decoration-none forhover arrow-icon "
                    style={{
                      color:
                        location.pathname === "/customdevelopment"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <NavLink
                    as={Link}
                    to="/customdevelopment"
                    className="text-decoration-none forhover nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#0FA4D2" : "black",
                    })}
                  >
                    Custom Software Development
                  </NavLink>
                </div>
                <div className="d-flex align-items-center  gap-2 forhover-parent">
                  <FaArrowRightLong
                    className="text-decoration-none forhover arrow-icon "
                    style={{
                      color: location.pathname === "/iot" ? "#0FA4D2" : "black",
                    }}
                  />
                  <NavLink
                    as={Link}
                    to="/iot"
                    className="text-decoration-none forhover nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#0FA4D2" : "black",
                    })}
                  >
                    Internet of Things (IoT)
                  </NavLink>
                </div>
                <div className="d-flex align-items-center  gap-2 forhover-parent">
                  <FaArrowRightLong
                    className="text-decoration-none forhover arrow-icon "
                    style={{
                      color:
                        location.pathname === "/blockchain"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <NavLink
                    as={Link}
                    to="/blockchain"
                    className="text-decoration-none forhover nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#0FA4D2" : "black",
                    })}
                  >
                    Blockchain Development
                  </NavLink>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center gap-2 mt-md-2 forhover-parent">
                  <FaArrowRightLong
                    className="text-decoration-none forhover arrow-icon "
                    style={{
                      color:
                        location.pathname === "/digitalmarketing"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <NavLink
                    as={Link}
                    to="/digitalmarketing"
                    className="text-decoration-none forhover nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#0FA4D2" : "black",
                    })}
                  >
                    Digital Marketing
                  </NavLink>
                </div>
                <div className="d-flex align-items-center  gap-2 forhover-parent ">
                  <FaArrowRightLong
                    className="text-decoration-none forhover arrow-icon "
                    style={{
                      color:
                        location.pathname === "/uiux" ? "#0FA4D2" : "black",
                    }}
                  />
                  <NavLink
                    as={Link}
                    to="/uiux"
                    className="text-decoration-none forhover nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#0FA4D2" : "black",
                    })}
                  >
                    UI/UX Design
                  </NavLink>
                </div>
                <div className="d-flex align-items-center  gap-2 forhover-parent">
                  <FaArrowRightLong
                    className="text-decoration-none forhover arrow-icon "
                    style={{
                      color:
                        location.pathname === "/cloudsecurity"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <NavLink
                    as={Link}
                    to="/cloudsecurity"
                    className="text-decoration-none forhover nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#0FA4D2" : "black",
                    })}
                  >
                    Cloud Services
                  </NavLink>
                </div>
                <div className="d-flex align-items-center  gap-2  forhover-parent">
                  <FaArrowRightLong
                    className="text-decoration-none forhover arrow-icon "
                    style={{
                      color:
                        location.pathname === "/itcounslant"
                          ? "#0FA4D2"
                          : "black",
                    }}
                  />
                  <NavLink
                    as={Link}
                    to="/itconsultant"
                    className="text-decoration-none forhover nav-link"
                    style={({ isActive }) => ({
                      color: isActive ? "#0FA4D2" : "black",
                    })}
                  >
                    IT Consultation
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="text-end">
              <img
                src={require("../../images/solutionsideimg.png")}
                className="img-fluid p-4 imgforsol col-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Solutiondrop