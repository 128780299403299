import React from 'react'
// import { CustomerItems } from './CustomerItems';
import { MdArrowOutward } from "react-icons/md";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectItem } from './ProjectItems';
import Popup from './Popup';


function ProjectShow() {

    const [showAll, setShowAll] = useState(false);
        const [modal, setModal] = useState(false);       
         const [currentData, setCurrentData] = useState();
       const navigate=useNavigate();
     const visibleProjects = showAll ? ProjectItem : ProjectItem.slice(-3);
       const handleNavigate=()=>{
        window.scrollTo(0,0)
       navigate("/portfolio");
       }
  return (
    <div className=''>
      <button
        className="text-end me-md-4 pb-0 col-11 forhover mt-md-2 mt-3"
        // onClick={() => setShowAll(true)}
        onClick={handleNavigate}
      >
        View All Projects <MdArrowOutward className="mx-md-1" size={20} />
      </button>
      {/* )} */}
      <div className="container mt-md-4 mt-3">
        <div className="d-flex flex-wrap">
          {visibleProjects
            .slice(0)
            .reverse()
            .map((data, i) => (
              // <div
              //   key={i}
              //   className="col-md-6 px-3 pb-4 "
              //   style={{ cursor: "pointer" }}
              //   onClick={() => {
              //     setModal(true);
              //     setCurrentData(data);
              //     // document.body.style.overflow = "hidden";
              //   }}
              // >
              //   <img
              //     src={data.img}
              //     className="img-fluid rounded-3 col-11 "
              //     alt={`Image ${i}`}
              //   />
              //   <div className="mt-md-3 mt-3 mx-md-0 mx-3 ">
              //     <h5 className="text-start mx-md-4">{data.heading}</h5>
              //     <p className="text-start mx-md-4">{data.subHeading}</p>
              //   </div>
              // </div>

              <div
                key={i}
                className="col-md-4 px-3 mb-3 "
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setModal(true);
                  setCurrentData(data);
                  // document.body.style.overflow = "hidden";
                }}
              >
                <img
                  src={data.img}
                  className="img-fluid rounded-top"
                  alt={`Image ${i}`}
                />

                <div className="text-center project-text  col-12 p-2 ">
                  <h5 className="text-head">{data.heading}</h5>
                  <p className="text-inner pforimac">{data.subHeading}</p>
                </div>
              </div>
            ))}
        </div>

        <Popup modal={modal} setModal={setModal} currentData={currentData} />
        {/* {!showAll && ( */}
      </div>
    </div>
  );
}

export default ProjectShow