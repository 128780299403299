import React from "react";
import "./about.css";
import Founders from "./Founders";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FaLinkedin } from "react-icons/fa";
import { useEffect } from "react";

export default function About() {
  // const [showPopup, setShowPopup] = useState(false);

  const widthh = window.innerWidth;

  const [hoveredMember, setHoveredMember] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs on

  const teamMembers = [
    {
      name: "Yash Tatiya",
      img: require("../images/yashtatiyaimg.png"),
      designation: "Founder and Director",
      link: "https://www.linkedin.com/in/yash-vaibhav-tatiya-a1925b155/",
    },
    {
      name: "Yash Baldota",
      img: require("../images/yash.png"),
      designation: "Business Anaylst",
      link: "https://www.linkedin.com/in/yash-baldota/",
    },
    {
      name: "Ms Ayushi ",
      img: require("../images/aayushi.png"),
      designation: "UI/UX Designer",
      link: "https://www.linkedin.com/in/ayushii21/",
    },
    {
      name: "Mayuresh Kumbhar",
      img: require("../images/mayuresh.png"),
      designation: "Developer",
      link: "https://www.linkedin.com/in/mayuresh-kumbhar/",
    },
    {
      name: "Vishwas Walhekar",
      designation: "Developer",
      img: require("../images/vish.png"),
      link: "https://www.linkedin.com/in/vishwas-walhekar/",
    },
    {
      name: "Shubham Darge ",
      designation: "QA Tester",
      img: require("../images/shubh.png"),
      link: "https://www.linkedin.com/in/shubham-darge-604883266?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      name: "Moin Shaikh",
      designation: "Developer",
      img: require("../images/moin.png"),
      link: "https://www.linkedin.com/in/moinuddin-shaikh-358b76257/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    },
    {
      name: "Nagesh Sontakke ",
      designation: "Developer",
      img: require("../images/nag.png"),
      link: "https://www.linkedin.com/in/nagesh-sontakke-897602230/",
    },
    {
      name: "Aishwarya Deshmukh ",
      img: require("../images/aish.png"),
      designation: "Developer",
      link: "https://www.linkedin.com/in/aishwarya-deshmukh-02a030222",
    },
    {
      name: "Fakhruddin Modi",
      designation: "Marketing Specialist",
      img: require("../images/fakrudiin.png"),
      link: "https://www.linkedin.com/in/fakhruddin-modi/",
    },
    {
      name: "Saurabh Umbare  ",
      designation: "Developer",
      img: require("../images/saur.png"),
      link: "https://www.linkedin.com/in/saurabh-umbare-3486b3216",
    },

    {
      name: "Siddhant Chopda ",
      designation: "Developer",
      img: require("../images/sid.png"),
      link: "https://www.linkedin.com/in/siddhantchopda/",
    },
    {
      name: "Chaitanya Chikhalthane ",
      designation: "UI/UX Designer",
      img: require("../images/chaitanya.png"),
      link: "https://www.linkedin.com/in/chaitanya-chikhalthane-7598521a0/",
    },
    {
      name: "Ajay Shinde",
      designation: "Receptionist",
      img: require("../images/ajay.png"),
    },
    {
      name: "",
      img: "",
    },
  ];

  return (
    <>
      {/* Banner  */}
      <div className="solution-banner position-relative">
        <img
          src={require("../images/aboutUsImages/aboutusbannerbg.png")}
          width="100%"
          alt="Banner"
          className="img-fluid aboutbanner"
        />
        <div className="banner-inner text-center text-white">
          <h1 className="mb-lg-4">About Us</h1>
          <p className="pforimac">
            Your Destination for Digital Transformation: Explore our tale of
            turning possibilities into realities with state-of-the-art IT
            solutions.
          </p>
        </div>
      </div>

      {/* Who we are  */}
      <div
        className={`business-success d-flex flex-wrap justify-content-between ${
          widthh < 800 && widthh > 700 ? "container" : ""
        }`}
      >
        <div className="col-md-5 align-self-end d-none d-md-block">
          <img
            src={require("../images/aboutUsImages/after-banner1.png")}
            alt="About Img"
            height="350"
            width="100%"
            className="img-fluid abt-exp-img "
          />
        </div>

        <div className="col-md-6 pe-md-5 px-2 ">
          <div className="text-md-end text-center pt-3 ">
            <h1 className="light-heading my-xl-4 my-lg-4 text-uppercase text-end">
              # Who WE ARE?
            </h1>
          </div>
          <div className="end-pad">
            <h1 className="my-xl-5 my-lg-4 my-3">
              Increasing Your Business{" "}
              <span className="primary-color">Success</span> With Technology
            </h1>
            <p className="my-xl-5 my-lg-4 my-3 pforimac">
              A leading IT company dedicated to providing you a custom solution
              and service that empowers your business to achieve its goals by
              ensuring seamless integration, scalable growth, and unparalleled
              support every step of the way.
            </p>
            <h4 className="fw-light mb-lg-4 mb-2">Want to know more?</h4>
            <p className="mb-4 mb-md-0 pforimac">
              Let us take you to a brief tour on what we do.
            </p>
          </div>
        </div>

        <div className="col-md-5 col-12 align-self-end d-block d-md-none">
          <img
            src={require("../images/aboutUsImages/after-banner.png")}
            alt="About Img"
            height="350"
            width="100%"
            className="img-fluid abt-exp-img"
          />
        </div>
      </div>

      {/* Founders */}
      <Founders />

      {/* What we do  */}
      <div className="bg-needs pb-5">
        <div className="container">
          <div className="text-md-end text-center pt-3">
            <h1 className="light-heading py-4 text-uppercase text-end">
              # WhAT WE DO?
            </h1>
          </div>
          <h1 className="pb-5 hand ">
            Handling All Your <span className="primary-color">IT Needs</span> At{" "}
            <br className="d-none d-md-block" /> One Place
          </h1>

          <div className="d-flex position-relative flex-wrap justify-content-around">
            <div className="col-lg-3 col-md-3 col-9 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0 ">
              <img
                src={require("../images/aboutUsImages/design.png")}
                alt="Needs Img"
                width="30%"
                className="img-fluid pad-img ms-2"
              />
              <h4 className="ms-2">DESIGN</h4>
              <p className="secondary-light-txt ms-2 pforimac ">
                We understand your problem and discuss all the possible solution
                you like, to give you the best in a one-to-one conference.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-9 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
              <img
                src={require("../images/aboutUsImages/develop.png")}
                alt="Needs Img"
                width="30%"
                className="img-fluid pad-img ms-2"
              />
              <h4 className="ms-2">DEVELOP</h4>
              <p className="secondary-light-txt ms-2 pforimac ">
                The most interesting part where we ideate the direction to the
                best solution your business needs.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-9 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
              <img
                src={require("../images/aboutUsImages/deploy.png")}
                alt="Needs Img"
                width="30%"
                className="img-fluid pad-img ms-2"
              />
              <h4 className="ms-2">DEPLOY</h4>
              <p className="secondary-light-txt ms-2 pforimac">
                We are not done yet until we talk out all you have in mind for
                the future perspective and success of your business.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-9 mx-auto need-pad py-2 py-md-0 pe-md-3 pe-lg-0 pe-0">
              <img
                src={require("../images/aboutUsImages/maintain8.png")}
                id="maintain"
                alt="Needs Img"
                width="30%"
                className="img-fluid pad-img ms-2"
              />
              <h4 className="ms-2">MAINTAIN</h4>
              <p className="secondary-light-txt ms-2 pforimac">
                Rest easy, as we tend to nurturing and safeguarding your
                creations for enduring excellence.
              </p>
            </div>

            <hr class="position-absolute hrStyle d-none d-md-block" />
          </div>
        </div>
      </div>

      {/* Process  */}
      <div className="container process">
        <div className="text-md-end text-center pt-3">
          <h1 className="light-heading py-4 text-uppercase"># How, you Ask?</h1>
        </div>
        <h1 className="pb-5">
          Let's drive you through our <br />
          <span className="primary-color">Process</span>
        </h1>

        <div className="d-flex flex-wrap justify-content-around  align-items-end">
          <div className="col-lg-3 col-md-5 int-pad">
            <img
              src={require("../images/aboutUsImages/process-1.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-lg-7 col-md-5 ending-pad int-pad">
            <h3 className="secondary-light-txt mb-3">Intially,</h3>
            <p className="pforimac">
              We conduct in-depth research to develop a comprehensive
              understanding of your business needs and goals.
            </p>
            <p className="pforimac">
              We use our research to develop customized IT solutions that are
              tailored to your unique business requirements.
            </p>
          </div>

          <div className="col-lg-3 col-md-5 int-pad d-block d-md-none">
            <img
              src={require("../images/aboutUsImages/process-2.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-lg-7 col-md-5 starting-pad int-pad">
            <h3 className="secondary-light-txt mb-3">Followed by,</h3>
            <p className="pforimac">
              We provide a range of IT solutions to help you achieve your
              business goals. Our IT solutions are designed to streamline your
              business processes and improve efficiency.
            </p>
            <p className="pforimac">
              We offer a variety of IT solutions to meet your specific needs,
              including consulting, design, development, and support.
            </p>
          </div>
          <div className="col-lg-3 col-md-5 int-pad d-none d-md-block">
            <img
              src={require("../images/aboutUsImages/process-2.png")}
              alt=""
              className="img-fluid"
            />
          </div>

          <div className="col-lg-3 col-md-5 int-pad">
            <img
              src={require("../images/aboutUsImages/process-3.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-lg-7 col-md-5 ending-pad int-pad">
            <h3 className="secondary-light-txt mb-3">Oh wait! There's more.</h3>
            <p className="pforimac">
              We continuously optimize your IT solutions to ensure that they are
              functioning at their best.
            </p>
            <p className="pforimac">
              Our team is dedicated to improving the performance and
              effectiveness of your solution to help you achieve your business
              objectives.
            </p>
          </div>
        </div>
      </div>

      {/* Special us  */}
      <div className="bgabout py-md-5 pb-5">
        <div className="container pb-lg-3">
          <div className="text-md-end text-center pt-3">
            <h1 className="light-heading py-4 text-uppercase">
              Our Special Touch
            </h1>
          </div>
          {/* <h1 className="pb-5">
            It's <span className="primary-color">Our Philosophy</span> That{" "}
            <br /> Sets Us Apart
          </h1> */}

          <div className=" col-md- col-11 bg-white mx-auto box-shodow px-5 p-5">
            <figure className="mb-0 position-relative">
              <blockquote className="blockquote mb-0 ">
                <h1 className="text-center mb-0 pforimac">
                  <b>What you build should</b> <br />
                  <span className="texthead">change the world.</span>
                </h1>
              </blockquote>
            </figure>
          </div>
        </div>
      </div>
      {/* out team */}
      <div className="p-md-5">
        <div className="text-md-start text-center pt-3">
          <h1 className="light-heading py-4 text-uppercase">
            #That’s our Team
          </h1>
        </div>
        <div className="text-md-end text-center pt-3 px-md-0 px-4">
          <h1 className=" py-md-4">
            Brains Behind <span className="texthead">Datart Infotech</span>
          </h1>
        </div>

        <div className="container">
          <div className=" d-flex justify-content-between p-md-4 flex-wrap">
            {teamMembers.map((member, index) => (
              <div
                key={index}
                className="col-md-4 col-6"
                onMouseEnter={() => setHoveredMember(index)}
                onMouseLeave={() => setHoveredMember(null)}
              >
                <div
                  className="d-flex align-items-center justify-content gap-3 position-relative mt-md-3 mt-3"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={member.img}
                    className="img-fluid col-md-2 col-3"
                    alt={member.name}
                  />
                  <div>
                    {member.link ? (
                      <Link
                        to="#"
                        className="text-decoration-none"
                        onClick={() => window.open(member.link, "_blank")}
                      >
                        <h5 className="text-black mobfont">{member.name}</h5>
                        <p className="text-black mobprara">
                          {member.designation}
                        </p>
                      </Link>
                    ) : (
                      <>
                        <h5 className="text-black mobfont">{member.name}</h5>
                        <p className="text-black mobprara">
                          {member.designation}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mx-md-4 mt-md-5 p-md-3 p-3 ">
          <h1>
            Beyond Work, Moments that{" "}
            <span className="texthead">Bring Us Together</span>
          </h1>
          <p>
            At Datart, we celebrate teamwork and diversity. Our lively
            debates—whether about <br />
            technology or lunch—fuels our creativity.
          </p>
        </div>
        <div className="bgforphoto mt-md-3 d-md-block d-none">
          <img
            src={require("../images/groupphoto.png")}
            className="img-fluid p-md-5 p-3"
          />
        </div>
        {windowWidth < 480 && (
          <>
            <div className="bgforphoto mt-2">
              <img
                src={require("../images/groupphotomob.png")}
                className="img-fluid p-3"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
