import React from 'react'
import './Thanku.css';
import { Link, useNavigate } from 'react-router-dom';

function Thanku() {
  const navigate=useNavigate();

  const handleportfolio=()=>{
    window.scrollTo(0,0);
   navigate("/webdevelopment");
  }
  return (
    <div>
      <div className="thank-you-bg">
        <div className="container pt-3">
          <h1 className="text-center mt-5">Thank You!</h1>
          <div className="text-center my-5">
            <img
              src={require("../../images/thankyou.png")}
              className="img-fluid col-2"
            />
          </div>
          <h3 className="text-center">
            We appreciate your interest in{" "}
            <span style={{ color: "#017B9F" }}>Datart Infotech.</span> <br />A
            member of our team will be in touch with you shortly.
          </h3>
          <h5 className="my-4 text-center">
            Learn more about our expertise by browsing through our highlighted
            Services.
          </h5>

          {/* <div className="text-center mt-5">
            <Link to="/#projects">
              <button
                style={{ backgroundColor: "rgb(34, 147, 187)" }}
                className="text-decoration-none p-2 px-5 text-white rounded"
              >
                Explore Our Projects
              </button>
            </Link>
          </div> */}
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="btn border col-md-2  mt-md-4 rounded-5 abobutton"
              onClick={handleportfolio}
            >
             Visit Our Services
            </div>
            <div onClick={handleportfolio}>
              <img
                src={require("../../images/homeImages/aboutround.png")}
                className="img-fluid  col-7 mt-md-4 "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thanku